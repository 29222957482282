import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getSettings(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Settings/GetSettings", filter, authHeaders);
    return res.data;
  },
  async saveSettings(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Settings/Save", upload, authHeaders);
    return res.data;
  },
  async getStates(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Settings/GetStates", filter, authHeaders);
    return res.data;
  },
  async getCountries(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Settings/GetCountries", filter, authHeaders);
    return res.data;
  },
  async getPrefixes(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Settings/GetPrefixes", filter, authHeaders);
    return res.data;
  },
  async getSuffixes(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Settings/GetSuffixes", filter, authHeaders);
    return res.data;
  },
  async getContacts(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Settings/GetContacts", filter, authHeaders);
    return res.data;
  },
  async saveLeadContact(companyId, contact) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      `api/Settings/SaveLeadContact/${companyId}`,
      contact,
      authHeaders
    );
    return res.data;
  },
};
