<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title me-auto">Your Account</h3>

      <button
        class="btn btn-primary"
        @click.prevent="saveSettings"
        v-if="!saved"
      >
        Save changes
      </button>
      <button class="btn btn-primary disabled" v-else-if="saving">
        Saving
      </button>
      <button class="btn btn-primary disabled" @click.prevent v-else>
        Saved Changes
      </button>
    </div>
    <div class="card-body" v-if="account">
      <div class="row">
        <div class="col-12">
          <fieldset class="form-fieldset mb-4">
            <div class="mb-3">
              <b>Your Information</b>
            </div>

            <div class="row mb-3">
              <div class="col-md-2">
                <label class="form-label">Prefix</label>
                <select
                  class="form-control form-select"
                  v-model="account.prefix"
                >
                  <option v-bind:value="null">Select</option>
                  <option v-for="prefix in prefixes" :key="prefix.prefixid">
                    {{ prefix.prefix }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="form-label font-weight-bold">First Name</label>
                <input
                  :class="[
                    'form-control',
                    $v.account.firstName.$error ? 'is-invalid' : '',
                  ]"
                  id="validateFirstName"
                  ref="firstname"
                  v-model="$v.account.firstName.$model"
                  required
                />
                <div class="invalid-feedback">
                  <small> Please enter a valid first name. </small>
                </div>
              </div>
              <div class="col-md-4">
                <label class="form-label font-weight-bold">Last Name</label>
                <input
                  :class="[
                    'form-control',
                    $v.account.lastName.$error ? 'is-invalid' : '',
                  ]"
                  id="validateLastName"
                  ref="lastname"
                  v-model="$v.account.lastName.$model"
                  required
                />
                <div class="invalid-feedback">
                  <small> Please enter a valid last name </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="form-label">Suffix</label>
                <select
                  class="form-control form-select"
                  v-model="account.suffix"
                >
                  <option v-bind:value="null">Select</option>
                  <option v-for="suffix in suffixes" :key="suffix.suffixid">
                    {{ suffix.suffix }}
                  </option>
                </select>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label mt-1 font-weight-bold" for="title">
                Title:
              </label>
              <input
                :class="['form-control']"
                id="title"
                ref="title"
                v-model="account.title"
                required
              />
            </div>

            <div class="row mb-3">
              <div class="col-md-5">
                <label class="form-label font-weight-bold">Phone:</label>
                <input
                  :class="[
                    'form-control',
                    $v.account.phone.$error ? 'is-invalid' : '',
                  ]"
                  id="validatePhone"
                  ref="phone"
                  v-model="$v.account.phone.$model"
                  required
                />
                <div class="invalid-feedback">
                  <small>Please enter a valid phone number.</small>
                </div>
              </div>
              <div class="col-md-1">
                <label class="form-label">Ext:</label>
                <input class="form-control" v-model="account.phoneext" />
              </div>

              <div class="col-md-6">
                <label class="form-label">Phone (Cell):</label>
                <input class="form-control" v-model="account.cellphone" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-5">
                <label class="form-label">Phone (Alt):</label>
                <input :class="['form-control']" v-model="account.phone2" />
              </div>
              <div class="col-md-1">
                <label class="form-label">Ext:</label>
                <input class="form-control" v-model="account.phone2ext" />
              </div>
              <div class="col-md-6">
                <label class="form-label">Fax:</label>
                <input class="form-control" v-model="account.fax" />
              </div>
            </div>
          </fieldset>

          <fieldset class="form-fieldset">
            <div class="mb-3">
              <b>Company Address Information</b>
            </div>

            <div class="mb-3">
              <label class="form-label mt-1 font-weight-bold">
                Address (line 1):
              </label>
              <input
                :class="['form-control']"
                id="validateAddress1"
                ref="address1"
                v-model="account.address"
                required
              />
              <div class="invalid-feedback">
                <small>Please enter a valid address line 1.</small>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label mt-1">Address (line 2):</label>
              <input class="form-control" v-model="account.address2" />
            </div>

            <div class="mb-3">
              <label class="form-label font-weight-bold" for="country">
                Country:
              </label>
              <select
                :class="['form-select country-select']"
                id="country"
                ref="country"
                v-model="account.country"
                required
              >
                <option v-bind:value="-1">Select</option>
                <option v-for="country in countries" :key="country.countryid">
                  {{ country.country }}
                </option>
              </select>
              <div class="invalid-feedback">
                <small>Please enter a valid country</small>
              </div>
            </div>

            <div class="mb-3" v-if="showRegion">
              <label class="form-label font-weight-bold">Region:</label>
              <input class="form-control" v-model="account.region" />
            </div>

            <div class="row mb-3" v-else>
              <div class="col-md-4">
                <label class="form-label font-weight-bold" for="city">
                  City:
                </label>
                <input
                  :class="['form-control']"
                  id="city"
                  ref="city"
                  v-model="account.city"
                  required
                />
                <div class="invalid-feedback">
                  <small>Please enter a valid city.</small>
                </div>
              </div>
              <div class="col-md-4">
                <label class="form-label font-weight-bold" for="state">
                  {{ stateLabel }}:
                </label>
                <select
                  :class="['form-control form-select']"
                  id="state"
                  ref="state"
                  v-model="account.state"
                  required
                >
                  <option v-bind:value="-1">Select</option>
                  <option v-for="state in showStates" :key="state.stateid">
                    {{ state.state }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <small> Please select a valid state. </small>
                </div>
              </div>
              <div class="col-md-4">
                <label class="form-label font-weight-bold" for="postalCode">
                  Postal Code:
                </label>
                <input
                  :class="['form-control']"
                  id="postalCode"
                  ref="postalcode"
                  v-model="account.postalCode"
                  required
                />
                <div class="invalid-feedback">
                  <small>Please enter a valid postal code.</small>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsService from "../../../services/SettingsService";
import { required } from "vuelidate/lib/validators";
import ProfileService from "../../../services/ProfileService";
import TrackingService from "../../../services/TrackingService";
import { getInstance } from "@/auth/auth";

export default {
  name: "account.profile.profile",
  data() {
    return {
      account: null,
      prefixes: [],
      suffixes: [],
      states: [],
      canadianProvinces: [],
      countries: [],
      save: null,
      saving: false,
      saved: false,
    };
  },
  validations: {
    account: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      phone: {
        required,
      },
    },
  },
  computed: {
    showStates() {
      if (this.account.country == "Canada") {
        return this.canadianProvinces;
      } else {
        return this.states;
      }
    },
    showRegion() {
      return (
        this.account &&
        this.account.country &&
        this.account.country != "United States" &&
        this.account.country != "Canada"
      );
    },
    stateLabel() {
      if (!this.account.country) {
        return "State";
      } else if (this.account.country == "United States") {
        return "State";
      } else if (this.account.country == "Canada") {
        return "Province";
      } else {
        return "";
      }
    },
  },
  watch: {
    account: {
      handler(_, old) {
        if (!old) {
          return;
        }

        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {
    async getCountries() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.countries = await SettingsService.getCountries(companyFilter);
    },
    async getStates() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.states = await SettingsService.getStates(companyFilter);
      this.canadianProvinces = await ProfileService.getCanadianProvinces(
        companyFilter
      );
    },
    async getPrefixes() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.prefixes = await SettingsService.getPrefixes(companyFilter);
    },
    async getSuffixes() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.suffixes = await SettingsService.getSuffixes(companyFilter);
    },
    async saveSettings() {
      this.$v.$touch();
      if(this.$v.$invalid)
      {
        return;
      }
      
      TrackingService.track("updated their personal information");
      this.saving = true;
      await SettingsService.saveSettings(this.account);
	  let auth = getInstance();
	  await auth.identify();
      this.saving = false;
      this.saved = true;
    },
    async load() {
      await Promise.all([this.getPrefixes(), this.getSuffixes(), this.getCountries(), this.getStates()]);
      let filter = {
        Company: { Companyid: this.$auth.activeCompany.companyId },
      };
      let accountTask = SettingsService.getSettings(filter);
      let otherThingTask = SettingsService.getContacts(filter);
	  
	  let [account, otherThing] = await Promise.all([accountTask, otherThingTask]);

      delete account.password;
      this.account = account;
      this.account.companyID = this.$auth.activeCompany.companyId;
      this.account.contactID = this.account.contactID = otherThing.length > 0 ? otherThing[0].contactID : 0;
    },
  },
  async mounted() {
    await this.load();
  },
};
</script>
